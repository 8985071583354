<template>
  <div class="page-box">
    <div class="nav-fixed-box">
      <Nav class="nav-fixed" :navFixed="navFixed"  @click.native="scrollView($event)"></Nav>
    </div>
    <el-scrollbar ref="view-box" style="position: relative;flex: 1;height:100%;box-sizing: border-box;">
      <div class="view-box">
        <p style="font-size:27pt; line-height: 175%; margin:0pt 0pt 10pt; text-align:center"><a
            name="_055-1599121612466"></a><span
            style="color:#333333; font-size:27pt; font-weight:bold">应用安全技术要求</span></p>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_240-1599121613409"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_733-1604629306325"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="margin-top: 20pt;margin-bottom: 10pt;" class="nav-title" id="0"><a name="_250-1604629307180"></a><span
            style="color:#409eff; font-size:17pt; font-weight:bold">引言</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_662-1604629307180"></a><span
            style="color:#595959; font-size:12pt">移动应用软件是移动智能终端安全架构中关键的要素之一，其种类和数量持续增长，全面影响着现代社会的方方面面，成为不可替代的公共基础软设施。但因为恶意应用的安装和运行，对移动智能终端的操作系统安全性、稳定性和用户数据安全等产生了负面影响。在移动智能终端上通过安装安全可信的应用是抵御恶意应用的威胁的重要保障，也是在移动智能终端硬件和操作系统之间建立起信任传链条的基础。</span>
        </p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_427-1604629307181"></a><span
            style="color:#595959; font-size:12pt">移动应用软件开发者和运营者依据《中华人民共和国网络安全法》、《中华人民共和国密码法》《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号）、《移动智能终端应用软件预置和分发管理暂行规定》（工信部信管函〔2016]407号）、《关于开展APP侵害用户权益专项整治工作的通知》（工信部信管函〔2019〕337号）、《关于开展纵深推进APP侵害用户权益专项整治行动的通知》（工信部信管函〔2020〕164号）、《App违法违规收集使用个人信息行为认定方法》等相关法律法规和规范性文件要求，本着提升产品和服务的，要将安全贯穿到产品设计、开发、运营管理全流程全环节。</span>
        </p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_534-1604629307182"></a><span
            style="color:#595959; font-size:12pt">本标准仅给出了移动应用软件应满足的安全技术要求，对其的具体技术实现方式、测试方法等不作规定。关于移动智能终端的硬件安全要求，可参见GB/T</span><span
            style="color:#595959; font-size:12pt">&#xa0;</span><span
            style="color:#595959; font-size:12pt">35278-2018和TAF-WG4-AS0015-V1.0.0</span><span
            style="color:#595959; font-size:12pt">&#xa0;</span><span
            style="color:#595959; font-size:12pt">2018；操作系统软件安全性要求，可参见GB/T</span><span
            style="color:#595959; font-size:12pt">&#xa0;</span><span
            style="color:#595959; font-size:12pt">34976-2017；用户数据安全可参加GB/T</span><span
            style="color:#595959; font-size:12pt">&#xa0;</span><span
            style="color:#595959; font-size:12pt">34978-2017和GB/T</span><span
            style="color:#595959; font-size:12pt">&#xa0;</span><span
            style="color:#595959; font-size:12pt">35273-2020。本标准中，凡涉及密码算法的相关内容，应符合法律、法规的规定和密码相关国家标准、行业标准的要求。</span>
        </p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_618-1604629307183"></a><span
            style="color:#595959; font-size:12pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_175-1604629307183"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="margin-top: 20pt;margin-bottom: 10pt;" class="nav-title" id="00"><a name="_357-1604629307183"></a><span
            style="color:#409eff; font-size:17pt; font-weight:bold">前言</span></p>
        <p style="font-size:12pt; line-height: 175%;"><a name="_584-1604629307183"></a><span
            style="color:#595959; font-size:12pt">本标准按照GB/T1.1-2009给出的规则起草。</span></p>
        <p style="font-size:12pt; line-height: 175%;"><a name="_976-1604629307184"></a><span
            style="color:#595959; font-size:12pt">本标准是移动智能终端生态联盟标准之一，该系列标准的名称及结构如下：</span></p>
        <p style="font-size:12pt; line-height: 175%;"><a name="_966-1604629307184"></a><span
            style="color:#595959; font-size:12pt">第1部分：兼容性技术要求；</span></p>
        <p style="font-size:12pt; line-height: 175%;"><a name="_628-1604629307184"></a><span
            style="color:#595959; font-size:12pt">第2部分：稳定性技术要求</span></p>
        <p style="font-size:12pt; line-height: 175%;"><a name="_853-1604629307184"></a><span
            style="color:#595959; font-size:12pt">第3部分：性能技术要求。</span></p>
        <p style="font-size:12pt; line-height: 175%;"><a name="_562-1604629307185"></a><span
            style="color:#595959; font-size:12pt">第4部分：功能性技术要求</span></p>
        <p style="font-size:12pt; line-height: 175%;"><a name="_624-1604629307185"></a><span
            style="color:#595959; font-size:12pt">第5部分：应用安全技术要求；</span></p>
        <p style="font-size:12pt; line-height: 175%;"><a name="_785-1604629307185"></a><span
            style="color:#595959; font-size:12pt">第6部分：个人信息保护技术要求。</span></p>
        <p style="font-size:12pt; line-height: 175%;"><a name="_047-1604629307186"></a><span
            style="color:#595959; font-size:12pt">本标准是移动智能终端生态联盟标准的第5部分。</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_192-1604629307186"></a><span
            style="color:#595959; font-size:12pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_083-1604631127346"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_682-1604631127513"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_600-1604629307187"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="margin-top: 20pt;margin-bottom: 10pt;" class="nav-title" id="1"><a name="_350-1604629307189"></a><span
            style="color:#409eff; font-size:17pt; font-weight:bold">一、　适用范围</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_958-1604629307189"></a><span
            style="color:#595959; font-size:12pt">本标准主要规定了基于安卓系统的移动智能终端上应用行为安全、应用开发安全和应用内容安全这三方面的安全性技术要求，其中应用行为安全包括了应用安装、应用运行的要求；应用开发安全包括了数据安全、编码安全、业务安全和隐私保护的要求；应用内容安全包括了基本要求、政治内容管理、暴恐血腥内容管理、违禁品内容管理、宗教种族等内容管理、色情内容管理、赌博彩票类内容管理、恶意扣费、欺诈内容管理和广告内容管理的要求。</span>
        </p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_629-1604629307190"></a><span
            style="color:#595959; font-size:12pt">本标准适用于从事安卓移动应用软件或平台服务的软件开发者和运营者，并为评估机构开展各类安卓应用的安全检查与评估工作提供参考。</span>
        </p>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_938-1604629735264"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="margin-top: 20pt;margin-bottom: 10pt;" class="nav-title" id="2"><a name="_397-1604629307191"></a><span
            style="color:#409eff; font-size:17pt; font-weight:bold">二、规范性引用文件</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_443-1604629307191"></a><span
            style="color:#595959; font-size:12pt">下列文件对于本文件的应用是必不可少的。凡是注日期的引用文件，仅注日期的版本适用于本文件。凡是不注日期的引用文件，其最新版本（包括所有的修改单）适用于本文件。</span>
        </p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt"><a name="_997-1604629307192"></a><span
            style="color:#595959; font-size:12pt">GB/T 35273-2020 </span><span
            style="color:#595959; font-size:12pt">&#xa0;</span><span
            style="color:#595959; font-size:12pt">信息安全技术 个人信息安全规范</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt"><a name="_444-1604629307192"></a><span
            style="color:#595959; font-size:12pt">GB/T 37729-2019 信息技术 智能移动终端应用软件（APP）技术要求</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt"><a name="_743-1604629307193"></a><span
            style="color:#595959; font-size:12pt">TAF-WG4-AS0051-V1.0.0:2019
            移动智能终端及应用软件用户个人信息保护实施指南</span><span style="color:#595959; font-size:12pt">&#xa0;</span><span
            style="color:#595959; font-size:12pt">第5部分：终端权限管理</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt"><a name="_686-1604629307193"></a><span
            style="color:#595959; font-size:12pt">移动智能终端生态联盟标准 第6部分：个人信息保护技术要求</span></p>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_923-1604629737412"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="margin-top: 20pt;margin-bottom: 10pt;" class="nav-title" id="3"><a name="_281-1604629307194"></a><span
            style="color:#409eff; font-size:17pt; font-weight:bold">三、术语、定义和缩略语</span></p>
        <p style="margin-top: 20pt;margin-bottom: 10pt;" class="nav-title" id="3-1"><a name="_061-1604629307195"></a><span
            style="color:#333333; font-size:14pt; font-weight:bold">3.1　术语和定义</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_613-1604629307196"></a><span
            style="color:#595959; font-size:12pt">GB/T</span><span
            style="color:#595959; font-size:12pt">&#xa0;</span><span
            style="color:#595959; font-size:12pt">35273-2020和GB/T 37729-2019界定的以及下列术语和定义适用于本标准。</span>
        </p>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_032-1604629307197"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="margin:0pt 0pt 10pt 21pt;margin-top: 10pt;" class="nav-title" id="3-1-1"><a name="_271-1604629307198"></a><span
            style="color:#595959; font-size:12pt; font-weight:bold">3.1.1 Intent</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt"><a name="_479-1604629307198"></a><span
            style="color:#595959; font-size:12pt">Intent是消息传递对象，用于从其他应用组件请求操作。</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt"><a name="_020-1604629307199"></a><span
            style="color:#595959; font-size:12pt">注1：可参见</span><a style="color:#595959"
            href="https://developer.android.com/guide/components/intents-filters?hl=zh-cn"><span
              style="color:#595959; font-size:12pt">https://developer.android.com/guide/components/intents-filters?hl=zh-cn</span></a><span
            style="color:#595959; font-size:12pt">。</span></p>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_097-1604629800918"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt;margin-top: 10pt;" class="nav-title" id="3-1-2"><a name="_061-1604629307201"></a><span
            style="color:#595959; font-size:12pt; font-weight:bold">3.1.2 个人信息　personal
            information</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt"><a name="_982-1604629307202"></a><span
            style="color:#595959; font-size:12pt">以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。</span>
        </p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt"><a name="_326-1604629307203"></a><span
            style="color:#595959; font-size:12pt">注1：个人信息包括姓名、出生日期、身份证件号码、个人生物识别信息、住址、通信通讯联系方式、通信记录和内容、账号密码、财产信息、征信信息、行踪轨迹、住宿信息、健康生理信息、交易信息等。</span>
        </p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt"><a name="_176-1604629307204"></a><span
            style="color:#595959; font-size:12pt">注2：关于个人信息的判定方法和类型可参见GB/T 35273-2020 中附录A。</span></p>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_397-1604629804260"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt;margin-top: 10pt;" class="nav-title" id="3-1-3"><a name="_721-1604629307205"></a><span
            style="color:#595959; font-size:12pt; font-weight:bold">3.1.3 个人敏感信息　personal sensitive
            information</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt"><a name="_896-1604629307207"></a><span
            style="color:#595959; font-size:12pt">一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。</span>
        </p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt"><a name="_450-1604629307207"></a><span
            style="color:#595959; font-size:12pt">注1：个人敏感信息包括身份证件号码、个人生物识别信息、银行账号、通信记录和内容、财产信息、征信信息、行踪轨迹、住宿信息、健康生理信息、交易信息、14岁以下（含）儿童的个人信息等。</span>
        </p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt"><a name="_168-1604629307209"></a><span
            style="color:#595959; font-size:12pt">注2：关于个人敏感信息的判定方法和类型可参见GB/T 35273-2020 中附录B。</span></p>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_044-1604630270266"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt;margin-top: 10pt;" class="nav-title" id="3-1-4"><a name="_521-1604629307210"></a><span
            style="color:#595959; font-size:12pt; font-weight:bold">3.1.4 敏感权限sensitive permission</span>
        </p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt"><a name="_800-1604629307211"></a><span
            style="color:#595959; font-size:12pt">敏感权限涵盖App需要涉及用户个人敏感信息的数据或资源的区域，或者可能会影响用户存储的数据或其他App的操作。例如，读取设备识别码（IMEI、WLAN、MAC地址等）的权限是一种敏感权限。</span>
        </p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt"><a name="_792-1604629307213"></a><span
            style="color:#595959; font-size:12pt">注1：敏感权限类别可参见TAF-WG4-AS0051-V1.0.0:2019的3.2和3.3。</span>
        </p>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_045-1604630268983"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt;margin-top: 10pt;" class="nav-title" id="3-1-5"><a name="_912-1604629307213"></a><span
            style="color:#595959; font-size:12pt; font-weight:bold">3.1.5 移动智能终端smart mobile
            terminal</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt"><a name="_762-1604629307214"></a><span
            style="color:#595959; font-size:12pt">设备能够接入通信网，有可供应用程序软件运行的操作系统，并能让用户安装、运行和卸载App的计算机产品。</span>
        </p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt"><a name="_253-1604629307214"></a><span
            style="color:#595959; font-size:12pt">注1：见[GB/T 37729-2019，定义3.1.2]</span></p>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_633-1604630267996"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt;margin-top: 10pt;" class="nav-title" id="3-1-6"><a name="_219-1604629307215"></a><span
            style="color:#595959; font-size:12pt; font-weight:bold">3.1.6 恶意应用malicious
            application</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt"><a name="_019-1604629307216"></a><span
            style="color:#595959; font-size:12pt">是指含有信息窃取、恶意扣费、诱骗欺诈、系统破坏等恶意行为及其他危害用户权益和网络安全的App。App的恶意行为，包括但不限于恶意扣费、信息窃取、远程控制、恶意传播、资费消耗、系统破坏、诱骗欺诈、流氓行为。</span>
        </p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt"><a name="_967-1604629307217"></a><span
            style="color:#595959; font-size:12pt">注1：恶意行为的详细定义见GB/T 37729-2019的4.7.2。</span></p>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_542-1604630265710"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt;margin-top: 10pt;" class="nav-title" id="3-1-7"><a name="_036-1604629307217"></a><span
            style="color:#595959; font-size:12pt; font-weight:bold">3.1.7 安全键盘</span><span
            style="color:#595959; font-size:12pt; font-weight:bold">&#xa0;</span><span
            style="color:#595959; font-size:12pt; font-weight:bold">secure keyboard</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt"><a name="_536-1604629307218"></a><span
            style="color:#595959; font-size:12pt">在应用中输入密码时，为用户提供键盘乱序、输入不记录、键盘防监听等方面安全防护能力的键盘。</span></p>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_050-1604630263900"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:14pt; line-height: 175%; margin:0pt 0pt 10pt;margin-top: 20pt;" class="nav-title" id="3-2"><a name="_925-1604629307219"></a><span
            style="color:#333333; font-size:14pt; font-weight:bold">3.2　缩略语</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_472-1604629307220"></a><span
            style="color:#595959; font-size:12pt">下列缩略语适用于本标准。</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt"><a name="_199-1604629307220"></a><span
            style="color:#595959; font-size:12pt">App：应用（Application）</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt"><a name="_991-1604629307221"></a><span
            style="color:#595959; font-size:12pt">Android：安卓（Android）</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt"><a name="_090-1604629307221"></a><span
            style="color:#595959; font-size:12pt">CNNVD：中国国家信息安全漏洞库（China National Vulnerability Database
            of Information Security）</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt"><a name="_276-1604629307222"></a><span
            style="color:#595959; font-size:12pt">CNNVE：中国通用漏洞披露 (China National Vulnerability Database
            of Information Security）</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt"><a name="_989-1604629307222"></a><span
            style="color:#595959; font-size:12pt">CNVD：国家信息安全漏洞共享平台（China</span><span
            style="color:#595959; font-size:12pt">&#xa0;</span><span
            style="color:#595959; font-size:12pt">National Vulnerability Database）</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt"><a name="_633-1604629307223"></a><span
            style="color:#595959; font-size:12pt">CVE：通用漏洞披露(Common</span><span
            style="color:#595959; font-size:12pt">&#xa0;</span><span
            style="color:#595959; font-size:12pt">Vulnerabilities and Exposures)</span><span
            style="color:#595959; font-size:12pt">&#xa0;</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt"><a name="_816-1604629307223"></a><span
            style="color:#595959; font-size:12pt">HMAC：哈希运算消息认证码（Hash-based Message Authentication
            Code）</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt"><a name="_460-1604629307223"></a><span
            style="color:#595959; font-size:12pt">SDK：软件开发工具包（Software</span><span
            style="color:#595959; font-size:12pt">&#xa0;</span><span
            style="color:#595959; font-size:12pt">Development Kit）</span></p>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_916-1604631075543"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_260-1604631077452"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:17pt; line-height: 175%; margin:0pt 0pt 10pt;margin-top: 20pt;" class="nav-title" id="4"><a name="_547-1604629307224"></a><span
            style="color:#409eff; font-size:17pt; font-weight:bold">四、移动智能终端安全架构</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_370-1604629307224"></a><span
            style="color:#595959; font-size:12pt">基于Android操作系统的移动智能终端安全架构包括了硬件安全、系统软件安全、App安全、接口安全、用户数据安全组成，其架构如下图1所示，详见参考文献[1]GB/T
            32927—2016。</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_400-1604629307226"></a><span
            style="color:#595959; font-size:12pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_276-1604631274184"></a><img
            src="@/assets/img/documents/003c3135-25aa-48ca-810b-925ca2bf4d46.001.png" width="553" height="298" alt="image.png"
            style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_974-1604629307226"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_193-1604629307228"></a><span
            style="color:#595959; font-size:12pt">本标准仅给出了App应满足的安全性技术要求，对App的具体技术实现方式、测试方法等不作规定。关于移动智能终端的硬件安全性要求，可参见[2]GB/T</span><span
            style="color:#595959; font-size:12pt">&#xa0;</span><span
            style="color:#595959; font-size:12pt">35278-2018和[3]TAF-WG4-AS0015-V1.0.0
            2018；操作系统软件安全性要求，可参见[4]GB/T</span><span
            style="color:#595959; font-size:12pt">&#xa0;</span><span
            style="color:#595959; font-size:12pt">34976-2017和[5]GB/T
            30284-2020；用户数据安全可参见[6]GB/T</span><span
            style="color:#595959; font-size:12pt">&#xa0;</span><span
            style="color:#595959; font-size:12pt">34978-2017和GB/T</span><span
            style="color:#595959; font-size:12pt">&#xa0;</span><span
            style="color:#595959; font-size:12pt">35273-2020。</span></p>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_457-1604631300213"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:17pt; line-height: 175%; margin:0pt 0pt 10pt;margin-top: 20pt;" class="nav-title" id="5"><a name="_698-1604629307229"></a><span
            style="color:#409eff; font-size:17pt; font-weight:bold">五、应用行为安全技术要求</span></p>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_480-1604629307229"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:14pt; line-height: 175%; margin:0pt 0pt 10pt;margin-top: 20pt;" class="nav-title" id="5-1"><a name="_384-1604629307231"></a><span
            style="color:#333333; font-size:14pt; font-weight:bold">5.1　应用安装</span></p>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_048-1604629307231"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt;margin-top: 10pt;" class="nav-title" id="5-1-1"><a name="_844-1604629307232"></a><span
            style="color:#595959; font-size:12pt; font-weight:bold">5.1.1　安装升级</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:20px 20pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">应用安装升级行为要求</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现自动化</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现云测</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">测试方法</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">举证或测试</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 授权：应用的下载、安装、升级必须获得用户授权。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 快捷方式：未经用户授权，禁止应用在桌面自动添加多个快捷方式图标。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">●
                  强制升级：除非出现CNVD、CNNVD、CVE、CNCVE等公开发布了6个月及以上的高危安全漏洞，否则禁止通过关闭服务的方式强制引导用户升级应用。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 升级方式：推荐通过应用市场升级应用，不推荐应用内部实现自升级、热升级等风险机制。</span>
              </p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; width:100pt;"><span
                  style="color:#595959; font-size:12pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt 21pt"><a name="_070-1604629307320"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt;margin-top: 10pt;" class="nav-title" id="5-1-2"><a name="_070-1604641802626"></a><span
            style="color:#595959; font-size:12pt; font-weight:bold">5.1.2　恶意安装</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:20px 20pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">应用安装行为要求</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现自动化</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现云测</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">测试方法</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">举证或测试</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 恶意诱导：禁止诱导、欺骗、恐吓、威胁用户下载安装其他应用。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 恶意模块：禁止安装包含欺诈、病毒、木马、后门等恶意模块的应用。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 漏洞模块：禁止安装包含国家信息安全漏洞共享平台（CNVD）、中国国家信息安全漏洞库（CNNVD）、CVE、CNCVE等公开发布了6个月及以上的高危安全漏洞的模块的应用。</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; width:100pt;"><span
                  style="color:#595959; font-size:12pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_549-1604629307402"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:14pt; line-height: 175%; margin:0pt 0pt 10pt;margin-top: 20pt;" class="nav-title" id="5-2"><a name="_899-1604641899182"></a><span
            style="color:#333333; font-size:14pt; font-weight:bold">5.2　应用运行</span></p>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_710-1604641900297"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt;margin-top: 10pt;" class="nav-title" id="5-2-1"><a name="_780-1604629307404"></a><span
            style="color:#595959; font-size:12pt; font-weight:bold">5.2.1　应用启动</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:20px 20pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">应用启动行为要求</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现自动化</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现云测</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">测试方法</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">举证或测试</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 后台自启动：如果没有相关功能需求，不推荐应用在后台自启动。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">●
                  流氓启动：禁止应用程序在首次启动时，要求用户进行敏感权限授权、或者执行指定任务，否则应用强制退出。</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; width:100pt;"><span
                  style="color:#595959; font-size:12pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt 21pt"><a name="_314-1604629307491"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt;margin-top: 10pt;" class="nav-title" id="5-2-2"><a name="_420-1604641907739"></a><span
            style="color:#595959; font-size:12pt; font-weight:bold">5.2.2　应用权限</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:20px 20pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">应用权限行为要求</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现自动化</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现云测</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">测试方法</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">举证或测试</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 透明：应列举具体、明确的权限类型、功能用途，知会用户、获得用户同意授权。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 关闭保护：禁止执行Root操作、关闭设备安全保护等。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 恶意诱导：禁止诱导、欺骗、恐吓、威胁用户开启开发者选项、开启USB调试。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 最小化：仅申请功能所需的最小范围的权限。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 频繁弹窗：禁止在应用运行过程中，频繁弹窗提示用户开放已拒绝授予的权限。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 敏感权限：遵守各国家地区的法律法规要求，敏感权限必须获得用户明示授权。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">●
                  选择权：用户可以选择拒绝授予某个权限，仅影响与该权限关联的应用功能的使用，不影响该应用的其他功能。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">●
                  辅助功能：应用使用辅助功能应获得用户同意并且明确告知用户应用使用辅助功能的目的，不得超出告知用户的使用范围或者进行自动安装、窃取用户隐私等行为。</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; width:100pt;"><span
                  style="color:#595959; font-size:12pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">注：关于频繁的定义，可参见[7]的3.2。</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_280-1604629307582"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt;margin-top: 10pt;" class="nav-title" id="5-2-3"><a name="_942-1604641914125"></a><span
            style="color:#595959; font-size:12pt; font-weight:bold">5.2.3　应用敏感操作</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:20px 20pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">应用敏感操作行为要求</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现自动化</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现云测</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">测试方法</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">举证或测试</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 特征：未经用户授权，禁止收集用户设备、系统版本、已安装应用等相关基础特征信息。</span>
              </p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">●
                  配置：未经用户授权，禁止应用修改系统相关配置、修改其他应用的配置、阻碍其他应用的正常运行。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 位置：用户未使用该应用时，禁止应用程序在后台持续获取位置信息、GPS定位。</span>
              </p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">●
                  通信：未经用户授权，禁止应用读取通信录、短信、通话记录，禁止应用直接进行拨打电话、发送短信。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 监控：未经用户授权，禁止应用直接进行截屏、读取相册、录音、摄像。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 网络：未经用户授权，禁止应用直接开启WLAN、蓝牙、NFC。</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; width:100pt;"><span
                  style="color:#595959; font-size:12pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_650-1604629307665"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt;margin-top: 10pt;" class="nav-title" id="5-2-4"><a name="_280-1604641920691"></a><span
            style="color:#595959; font-size:12pt; font-weight:bold">5.2.4　应用流氓行为</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:20px 20pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">应用流氓行为要求</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现自动化</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现云测</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">测试方法</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">举证或测试</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 常驻后台：如果没有相关功能需求以及用户明确授权，应用不应常驻后台。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">●
                  相互唤醒：如果没有相关功能需求以及用户明确授权，不推荐应用之间通过后台相互唤醒，无法关闭。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">●
                  消息常驻：如果没有相关功能需求以及用户明确授权，不推荐应用消息常驻通知栏、悬浮窗、锁屏页面、桌面。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">●
                  恶意覆盖：如果没有相关功能需求以及用户明确授权，应用不应悬浮窗、弹窗覆盖其他应用的显示窗口、页面。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 后台弹窗：如果没有相关功能需求，禁止应用在后台直接弹窗。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 数据隔离：禁止应用读取、修改其他应用的内存数据。</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; width:100pt;"><span
                  style="color:#595959; font-size:12pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_183-1604629307761"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:17pt; line-height: 175%; margin:0pt 0pt 10pt;margin-top: 20pt;" class="nav-title" id="6"><a name="_569-1604641926890"></a><span
            style="color:#409eff; font-size:17pt; font-weight:bold">六、应用开发安全技术要求</span></p>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_086-1604641934909"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:14pt; line-height: 175%; margin:0pt 0pt 10pt;margin-top: 20pt;" class="nav-title" id="6-1"><a name="_940-1604629307762"></a><span
            style="color:#333333; font-size:14pt; font-weight:bold">6.1　数据安全</span></p>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_287-1604629307762"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt;margin-top: 10pt;" class="nav-title" id="6-1-1"><a name="_684-1604629307763"></a><span
            style="color:#595959; font-size:12pt; font-weight:bold">6.1.1　机密性</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:20px 20pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">数据安全机密性要求</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现自动化</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现云测</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">测试方法</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">举证或测试</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">●
                  密钥安全：应提供安全的密钥存储机制，防止密钥在没有授权的情况下被泄露、修改、替代或者删除。私钥的存储要求保证机密性与完整性；禁止硬编码私钥等敏感信息，防止攻击者反编译代码后泄露私钥。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">●
                  访问控制：宜加密密钥数据库，避免在数据库中存储明文和敏感信息；涉及敏感信息需加密存储，且要有合适的加密强度，不应使用弱加密、不应采取明文形式存储或传输敏感信息。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">●
                  算法要求：必须选用标准加解密算法，并选用安全加密模式。禁止使用私有密码算法、过时密码算法（如RC4/DES/3DES等）、算法的不安全模式（默认加密模式为ECB，但ECB的安全性较弱，会使相同的明文在不同的时候产生相同的密文，容易遇到字典攻击），避免信息泄露。</span>
              </p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; width:100pt;"><span
                  style="color:#595959; font-size:12pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_936-1604629307845"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt;margin-top: 10pt;" class="nav-title" id="6-1-2"><a name="_075-1604641938962"></a><span
            style="color:#595959; font-size:12pt; font-weight:bold">6.1.2　完整性</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:20px 20pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">数据安全完整性要求</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现自动化</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现云测</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">测试方法</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">举证或测试</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 沙箱管控：默认启用沙箱技术，限定私有数据访问。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">●
                  访问控制：采用系统默认的授权机制请求数据。禁止通过CNVD、CNNVD、CVE、CNCVE等公开发布了6个月及以上的高危安全漏洞利用等越权行为访问或修改非本应用沙箱数据。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 数据校验：通过HMAC、签名值等方式校验传输数据完整性。</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; width:100pt;"><span
                  style="color:#595959; font-size:12pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_992-1604629307930"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:14pt; line-height: 175%; margin:0pt 0pt 10pt;margin-top: 10pt;" class="nav-title" id="6-2"><a name="_856-1604641944380"></a><span
            style="color:#333333; font-size:14pt; font-weight:bold">6.2　编码安全</span></p>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_769-1604641947700"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt;margin-top: 10pt;" class="nav-title" id="6-2-1"><a name="_372-1604629307931"></a><span
            style="color:#595959; font-size:12pt; font-weight:bold">6.2.1　编码规范</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:20px 20pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">编码安全规范</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现自动化</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现云测</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">测试方法</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">举证或测试</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">●
                  数据检查：应用必须针对输入、输出数据进行异常检查，例如注意空值判定及类型转换判断，防止由于解析异常参数导致的应用崩溃（本地拒绝服务）。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">●
                  开源合规：禁止使用存在CNVD、CNNVD、CVE、CNCVE等公开发布了6个月及以上的高危安全漏洞的旧版本开源软件、框架、函数库。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 接口安全：不宜沿用安卓系统废弃接口。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 禁止绕过：禁止通过提升权限等方式绕过系统推荐的标准权限申请、数据访问控制策略。</span>
              </p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 日志分级：应通过Log Level管控日志信息。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">●
                  关闭调试：正式产品发布前应关闭调试，即android:debuggable</span><span
                  style="color:#595959; font-size:12pt">&#xa0;</span><span
                  style="color:#595959; font-size:12pt">属性设置为 false。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● TargetSDK: 应用的API Level不能低于26（即TargetSdk
                  Version不能低于26），推荐API Level为29。</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; width:100pt;"><span
                  style="color:#595959; font-size:12pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_514-1604629308025"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt;margin-top: 10pt;" class="nav-title" id="6-2-2"><a name="_154-1604641954586"></a><span
            style="color:#595959; font-size:12pt; font-weight:bold">6.2.2　审计加固</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:20px 20pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">审计加固</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现自动化</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现云测</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">测试方法</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">举证或测试</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 代码审计：应用必须通过自动化、或人工的代码审计检查。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 混淆：应用发布之前必须进行混淆。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 加固：宜在应用发布之前进行App加固，降低对App反汇编分析等风险。</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; width:100pt;"><span
                  style="color:#595959; font-size:12pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_110-1604629308116"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt;margin-top: 10pt;" class="nav-title" id="6-2-3"><a name="_432-1604641959826"></a><span
            style="color:#595959; font-size:12pt; font-weight:bold">6.2.3　第三方SDK规范</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:20px 20pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">第三方SDK规范</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现自动化</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现云测</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">测试方法</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">举证或测试</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● SDK接入：至少应遵循GB/T 35273-2020的9.7；</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● SDK管控：禁止使用业界已披露存在风险的第三方SDK，可参考[9]的6.3。</span>
              </p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; width:100pt;"><span
                  style="color:#595959; font-size:12pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_926-1604629308208"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt;margin-top: 10pt;" class="nav-title" id="6-2-4"><a name="_528-1604641964902"></a><span
            style="color:#595959; font-size:12pt; font-weight:bold">6.2.4　应用权限规范</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:20px 20pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">应用权限规范</span></p>
            </td>
          </tr>
          <tr style="height:2pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现自动化</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现云测</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">测试方法</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">举证或测试</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 权限最小化：仅申请功能所需的最小权限。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 调试开关：默认关闭调试。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 组件导出：除非功能需要，默认禁止组件导出。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">●
                  数据传递：除非功能需要、并获得用户授权，否则禁止应用将从A应用获取的数据传递给B应用。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 备份功能：除非功能需要，默认关闭备份功能，即android:allowbackup 属性设置为
                  false。</span><span style="color:#595959; font-size:12pt">&#xa0;</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; width:100pt;"><span
                  style="color:#595959; font-size:12pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_427-1604629308300"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt;margin-top: 10pt;" class="nav-title" id="6-2-5"><a name="_332-1604641969612"></a><span
            style="color:#595959; font-size:12pt; font-weight:bold">6.2.5　组件安全规范</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:20px 20pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">组件安全规范</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现自动化</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现云测</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">测试方法</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">举证或测试</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">●
                  组件调用：无需暴露的组件应该显式的将android:exported设置为false。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">●
                  组件权限：禁止内部组件被外部程序调用，如需被外部调用，应检查调用者是否符合访问控制机制。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● Intent使用：禁止恶意构造篡改Intent参数，避免引发拒绝服务等风险。。</span>
              </p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; width:100pt;"><span
                  style="color:#595959; font-size:12pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_563-1604629308387"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt;margin-top: 10pt;" class="nav-title" id="6-2-6"><a name="_312-1604642013514"></a><span
            style="color:#595959; font-size:12pt; font-weight:bold">6.2.6　工具链安全规范</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:20px 20pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">工具链安全规范</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现自动化</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现云测</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">测试方法</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">举证或测试</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 工具安全：禁止使用非官方渠道提供的开发、编译、发布工具。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 环境安全：应确保应用开发环境的IT安全。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">●
                  密钥安全：应确保后台应用签名密钥、应用加密密钥、证书对应的私钥等关键密钥数据安全。</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; width:100pt;"><span
                  style="color:#595959; font-size:12pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_231-1604629308496"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:14pt; line-height: 175%; margin:0pt 0pt 10pt;margin-top: 20pt;" class="nav-title" id="6-3"><a name="_662-1604642020261"></a><span
            style="color:#333333; font-size:14pt; font-weight:bold">6.3　业务安全</span></p>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_956-1604629308496"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt;margin-top: 10pt;" class="nav-title" id="6-3-1"><a name="_642-1604629308497"></a><span
            style="color:#595959; font-size:12pt; font-weight:bold">6.3.1　业务鉴权规范</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:20px 20pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">业务鉴权规范</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现自动化</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现云测</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">测试方法</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">举证或测试</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 认证鉴权：应用或组件请求数据必须进行认证、鉴权。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 设备特征：云端应结合终端设备特征进行风险判定。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 第三方共享：与第三方共享数据，应通过token机制动态授权，云端管控。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">●
                  多因子校验：对于敏感信息（例如支付、隐私信息）操作，应进行PIN码、短信、指纹、面部识别等多因子校验。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">●
                  缓存凭据：除非相关法规标准允许、或者特殊业务场景下的安全例外，否则禁止在终端本地缓存业务核心凭据（例如认证码）。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 安全输入：对安全要求较高的密码等敏感信息，应提供安全键盘作为数据输入接口。</span>
              </p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; width:100pt;"><span
                  style="color:#595959; font-size:12pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_514-1604629308584"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt;margin-top: 10pt;" class="nav-title" id="6-3-2"><a name="_463-1604642025359"></a><span
            style="color:#595959; font-size:12pt; font-weight:bold">6.3.2　安全策略规范</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:20px 20pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">安全策略规范</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现自动化</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现云测</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">测试方法</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">举证或测试</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 时间戳：应用近场通信、或者本地鉴权应避免伪造时间戳攻击。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">●
                  指令安全：应用近场或远程的指令交互，必须考虑防重放、绕过认证、水平攻击、垂直攻击的风险。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">●
                  短信验证码：必须限制短信验证码的有效期。禁止多次失败后，自动绕过校验的后台逻辑错误。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 劫持防范：必须进行证书校验，有条件时尽量进行证书状态检查，防范劫持攻击。</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; width:100pt;"><span
                  style="color:#595959; font-size:12pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_875-1604629308671"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:14pt; line-height: 175%; margin:0pt 0pt 10pt;margin-top: 20pt;" class="nav-title" id="6-4"><a name="_514-1604642032636"></a><span
            style="color:#333333; font-size:14pt; font-weight:bold">6.4　隐私保护规范</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_170-1604629308671"></a><span
            style="color:#595959; font-size:12pt">本标准遵守隐私法律法规，详细的隐私保护要求在《移动智能终端生态联盟标准
            第6部分：个人信息保护技术要求》中定义。</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:20px 20pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">隐私保护规范</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现自动化</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现云测</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">测试方法</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">举证或测试</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">●
                  机密性：在数据传输期间对数据进行加密保护（例如HTTPS)，避免恶意劫持。支持TLS1.2及以上的版本。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 数据泄露：禁止明文存储、明文传输、日志直接打印敏感信息、以及用户密码等风险信息。</span>
              </p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">●
                  数据处理：应根据隐私法律法规、以及联盟的隐私标准要求，对敏感信息进行匿名化、去标识化、加密等隐私保护处理，并对数据进行完整性保护，避免恶意篡改。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 访问控制：在存储期限内对数据实施访问控制，避免未授权访问。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 日志审计：在存储期限内记录访问数据的行为日志，支持审计。</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; width:100pt;"><span
                  style="color:#595959; font-size:12pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_564-1604629308760"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:17pt; line-height: 175%; margin:0pt 0pt 10pt;margin-top: 20pt;" class="nav-title" id="7"><a name="_320-1604642046599"></a><span
            style="color:#409eff; font-size:17pt; font-weight:bold">七、应用内容安全要求</span></p>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_369-1604629308761"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:14pt; line-height: 175%; margin:0pt 0pt 10pt;margin-top: 10pt;" class="nav-title" id="7-1"><a name="_661-1604642048755"></a><span
            style="color:#333333; font-size:14pt; font-weight:bold">7.1　基本要求</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_444-1604629308762"></a><span
            style="color:#595959; font-size:12pt">GB/T</span><span
            style="color:#595959; font-size:12pt">&#xa0;</span><span
            style="color:#595959; font-size:12pt">37729-2019的4.1.2，适用于应用内容的安全要求。同时，应用内容安全应遵守现行法律规定及公序良俗的要求，不得含有令人不快或具有攻击性的内容，不会损坏用户设备，不会在使用过程造成人身伤害，包括但不限于下列要求：</span>
        </p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt"><a name="_050-1604629308763"></a><span
            style="color:#595959; font-size:12pt">a)</span><span
            style="color:#595959; font-size:12pt">&#xa0;</span><span
            style="color:#595959; font-size:12pt">不含政治内容；</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt"><a name="_932-1604629308764"></a><span
            style="color:#595959; font-size:12pt">b)</span><span
            style="color:#595959; font-size:12pt">&#xa0;</span><span
            style="color:#595959; font-size:12pt">不含暴恐血腥内容；</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt"><a name="_841-1604629308764"></a><span
            style="color:#595959; font-size:12pt">c)</span><span
            style="color:#595959; font-size:12pt">&#xa0;</span><span
            style="color:#595959; font-size:12pt">不含违禁品内容；</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt"><a name="_018-1604629308765"></a><span
            style="color:#595959; font-size:12pt">d)</span><span
            style="color:#595959; font-size:12pt">&#xa0;</span><span
            style="color:#595959; font-size:12pt">不含色情内容；</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt"><a name="_480-1604629308765"></a><span
            style="color:#595959; font-size:12pt">e)</span><span
            style="color:#595959; font-size:12pt">&#xa0;</span><span
            style="color:#595959; font-size:12pt">不含赌博或彩票类内容；不含恶意扣费、欺诈内容；</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt 21pt"><a name="_871-1604629308766"></a><span
            style="color:#595959; font-size:12pt">f)</span><span
            style="color:#595959; font-size:12pt">&#xa0;</span><span
            style="color:#595959; font-size:12pt">广告不存在违反《中华人民共和国广告法》、《互联网广告管理暂行办法》等内容。</span></p>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_166-1604629308767"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:14pt; line-height: 175%; margin:0pt 0pt 10pt;margin-top: 10pt;" class="nav-title" id="7-2"><a name="_076-1604642058700"></a><span
            style="color:#333333; font-size:14pt; font-weight:bold">7.2　政治内容管理要求</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:20px 20pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">政治内容管理要求</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现自动化</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现云测</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">测试方法</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">举证或测试</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">应用内容管理：应用内禁止含有政治谣言、涉及反政府、反社会以及其他涉及政治敏感内容。</span>
              </p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">用户行为管理：App禁止用户提交含有政治谣言、涉及反政府、反社会以及其他涉及政治敏感内容。</span>
              </p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; width:100pt;"><span
                  style="color:#595959; font-size:12pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_395-1604629308857"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:14pt; line-height: 175%; margin:0pt 0pt 10pt;margin-top: 10pt;" class="nav-title" id="7-3"><a name="_785-1604642064159"></a><span
            style="color:#333333; font-size:14pt; font-weight:bold">7.3　暴恐血腥内容管理要求</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:20px 20pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">暴恐血腥内容管理要求</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现自动化</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现云测</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">测试方法</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">应用内容管理：应用内禁止含有恐怖、暴力、血腥元素相关内容，包括但不限于人类或动物遭到杀害、残害、酷刑、虐待的写实描绘，鼓吹暴力，令人产生不适、恐惧的文字、图片、音频、视频内容等。在游戏中，“敌人”不能单单针对特定种族、文化、真实存在的政府或企业，或是任何其他真实存在的实体。</span>
              </p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">用户行为管理：应用禁止允许用户提交含有恐怖、暴力、血腥元素相关内容的文字、图片、音频、视频内容等。</span>
              </p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">举证或测试</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; width:100pt;"><span
                  style="color:#595959; font-size:12pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_960-1604629308943"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:14pt; line-height: 175%; margin:0pt 0pt 10pt;margin-top: 10pt;" class="nav-title" id="7-4"><a name="_521-1604642070370"></a><span
            style="color:#333333; font-size:14pt; font-weight:bold">7.4　违禁品内容管理要求</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:20px 20pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">违禁品内容要求</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现自动化</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现云测</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">测试方法</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">举证或测试</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">●
                  应用内容管理：应用禁止含有不负责任地使用枪支弹药及相关器材、违禁品的描述，或促进枪支弹药及相关器材交易、违禁品交易的文字、图片、音频、视频内容等。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 用户行为管理：应用禁止用户提交违禁物品交易或制作的文字、图片、视频、音频内容等。</span>
              </p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 销售：应用禁止含有枪支弹药及相关器材、违禁品交易功能。</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; width:100pt;"><span
                  style="color:#595959; font-size:12pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">涉及法律法规可参考</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_785-1604629309026"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:14pt; line-height: 175%; margin:0pt 0pt 10pt;margin-top: 10pt;" class="nav-title" id="7-5"><a name="_382-1604642075285"></a><span
            style="color:#333333; font-size:14pt; font-weight:bold">7.5　宗教、种族等内容管理要求</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:20px 20pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">宗教、种族内容管理要求</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现自动化</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现云测</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">测试方法</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">举证或测试</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">●
                  应用内容管理：应用禁止含有关于宗教、种族、性取向、性别或其他目标群体的诽谤或恶意内容等文字、图片、音频、视频等。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">●
                  用户行为管理：应用禁止用户提交关于宗教、种族、性取向、性别或其他目标群体的诽谤或恶意内容的文字、图片、音频、视频等。</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; width:100pt;"><span
                  style="color:#595959; font-size:12pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_919-1604629309137"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:14pt; line-height: 175%; margin:0pt 0pt 10pt;margin-top: 10pt;" class="nav-title" id="7-6"><a name="_979-1604642080393"></a><span
            style="color:#333333; font-size:14pt; font-weight:bold">7.6　色情内容管理要求</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:20px 20pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">色情内容管理要求</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现自动化</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现云测</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">测试方法</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">举证或测试</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">●
                  应用内容管理：应用禁止含有应用提供色情内容或色请服务等。（韦氏词典对“色情”一词的定义是：对性器官或性活动的露骨描述或展示，目的在于刺激性快感，而非带来美学价值或触发情感）</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 用户行为管理：应用禁止允许用户提交色情或隐晦色情内容。</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; width:100pt;"><span
                  style="color:#595959; font-size:12pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_935-1604629309231"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:14pt; line-height: 175%; margin:0pt 0pt 10pt;margin-top: 10pt;" class="nav-title" id="7-7"><a name="_418-1604642084803"></a><span
            style="color:#333333; font-size:14pt; font-weight:bold">7.7　赌博、彩票类内容管理要求</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:20px 20pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">赌博、彩票类内容管理要求</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现自动化</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现云测</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:11pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">测试方法</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">举证或测试</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 应用内容管理：应用禁止含有线上违规销售彩票、赌球、赌马或进行棋牌游戏赌博等内容。</span>
              </p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">● 用户行为管理：应用禁止允许用户提交虚假彩票信息。</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; width:100pt;"><span
                  style="color:#595959; font-size:12pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">体彩、福彩国家或地方官方推出的福利彩票类应用属于安全应用，例如“中国体育彩票”。</span>
              </p>
            </td>
          </tr>
        </table>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_019-1604629309311"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:14pt; line-height: 175%; margin:0pt 0pt 10pt;margin-top: 10pt;" class="nav-title" id="7-8"><a name="_663-1604642091159"></a><span
            style="color:#333333; font-size:14pt; font-weight:bold">7.8　恶意扣费、欺诈内容管理要求</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:20px 20pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">恶意扣费、欺诈内容管理要求</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现自动化</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现云测</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">测试方法</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">举证或测试</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">●
                  应用付费标准：付费标准需要公开透明且必须在用户知情并同意的情况下进行扣费，禁止存在包括但不限于用户不知情或未授权的情况下，自动订购付费服务或无二次确认直接扣费等情况。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">●
                  付费资质：(1)应用程序内存在的付费项需要符合国家法律法规；(2)特殊行业需要获得国家相关资质，不得无证经营，包括但不限于游戏行业需要获得《网络游戏出版物号（ISBN）》。</span></p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">●
                  应用欺诈：(1)应用不得存在欺骗性内容，诱导用户进行付费;(2)用户付费后，用户需获得应有的付费服务。</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:11pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_320-1604629309382"></a><span
            style=" font-size:11pt">&#xa0;</span></p>
        <p style="font-size:14pt; line-height: 175%; margin:0pt 0pt 10pt;margin-top: 10pt;" class="nav-title" id="7-9"><a name="_819-1604642099580"></a><span
            style="color:#333333; font-size:14pt; font-weight:bold">7.9　广告内容管理要求</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:20px 20pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">广告内容管理要求</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现自动化</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">是否实现云测</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">/</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">测试方法</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">●
                  广告内容管理要求：(1)广告内容不得存在违反《中华人民共和国广告法》、《互联网广告管理暂行办法》等内容，包括但不限于存在于政治谣言、涉及反政府、色情等；（2）应用不得存在欺骗，诱导点击形式的低质量推广广告或广告内容含有虚假内容等；（3）医疗、药品、特殊医学用途配方食品、医疗器械广告等法律、行政法规规定须经广告审查机关进行审查的特殊商品或者服务的广告，未经审查，不得发布，且互联网广告主应当对广告内容的真实性负责。</span>
              </p>
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">●
                  广告功能管理要求：（1）应用内广告应当具有可识别性，显著标明“广告”或其他广告标识；（2）应用内广告不得存在强制用户点击的情况，且必须含有显著的关闭按钮；（3）应用不得强制推送通知栏广告；（4）应用内广告不得频繁弹出影响用户体验。</span>
              </p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">&#xa0;</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; width:100pt;"><span
                  style="color:#595959; font-size:12pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt:vertical-align:middle">
              <p style="font-size:12pt; line-height: 175%; "><span
                  style="color:#595959; font-size:12pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_636-1604629309466"></a><span
            style="color:#595959; font-size:12pt">&#xa0;</span></p>
        <p style="margin-bottom: 10pt;"><a name="_899-1604629309467"></a><span
            style="color:#409eff; font-size:17pt; font-weight:bold">参考文献</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_026-1604629309467"></a><span
            style="color:#595959; font-size:12pt">[1]</span><span
            style="color:#595959; font-size:12pt">&#xa0;</span><span
            style="color:#595959; font-size:12pt">GB/T 32927—2016 信息安全技术</span><span
            style="color:#595959; font-size:12pt">&#xa0;</span><span
            style="color:#595959; font-size:12pt">移动智能终端安全架构.</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_039-1604629309468"></a><span
            style="color:#595959; font-size:12pt">[2]</span><span
            style="color:#595959; font-size:12pt">&#xa0;</span><span
            style="color:#595959; font-size:12pt">GB/T 35278-2017 信息安全技术</span><span
            style="color:#595959; font-size:12pt">&#xa0;</span><span
            style="color:#595959; font-size:12pt">移动终端安全保护技术要求.</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_432-1604629309468"></a><span
            style="color:#595959; font-size:12pt">[3]</span><span
            style="color:#595959; font-size:12pt">&#xa0;</span><span
            style="color:#595959; font-size:12pt">TAF-WG4-AS0015-V1.0.0 2018 移动智能终端安全能力技术要求.</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_381-1604629309469"></a><span
            style="color:#595959; font-size:12pt">[4]</span><span
            style="color:#595959; font-size:12pt">&#xa0;</span><span
            style="color:#595959; font-size:12pt">GB/T 30284-2020 信息安全技术</span><span
            style="color:#595959; font-size:12pt">&#xa0;</span><span
            style="color:#595959; font-size:12pt">移动通信智能终端操作系统安全技术要求(EAL 2级).</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_848-1604629309469"></a><span
            style="color:#595959; font-size:12pt">[5]</span><span
            style="color:#595959; font-size:12pt">&#xa0;</span><span
            style="color:#595959; font-size:12pt">GB/T 34976-2017 信息安全技术</span><span
            style="color:#595959; font-size:12pt">&#xa0;</span><span
            style="color:#595959; font-size:12pt">移动智能终端操作系统安全技术要求和测试评价方法.</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_351-1604629309470"></a><span
            style="color:#595959; font-size:12pt">[6]</span><span
            style="color:#595959; font-size:12pt">&#xa0;</span><span
            style="color:#595959; font-size:12pt">GB/T 34978—2017 信息安全技术</span><span
            style="color:#595959; font-size:12pt">&#xa0;</span><span
            style="color:#595959; font-size:12pt">移动智能终端个人信息保护技术要求.</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_750-1604629309471"></a><span
            style="color:#595959; font-size:12pt">[7]</span><span
            style="color:#595959; font-size:12pt">&#xa0;</span><span
            style="color:#595959; font-size:12pt">TC260-PG-20202A
            网络安全标准实践指南—移动互联网应用程序（App）收集使用个人信息自评估指南.</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_062-1604629309471"></a><span
            style="color:#595959; font-size:12pt">[8]</span><span
            style="color:#595959; font-size:12pt">&#xa0;</span><span
            style="color:#595959; font-size:12pt">TC260-PG-20204A
            网络安全标准实践指南——移动互联网应用程序(App)系统权限申请使用指引（征求意见稿）.</span></p>
        <p style="font-size:12pt; line-height: 175%; margin:0pt 0pt 10pt"><a name="_365-1604629309473"></a><span
            style="color:#595959; font-size:12pt">[9]</span><span
            style="color:#595959; font-size:12pt">&#xa0;</span><span
            style="color:#595959; font-size:12pt">信息安全技术</span><span
            style="color:#595959; font-size:12pt">&#xa0;</span><span
            style="color:#595959; font-size:12pt">移动互联网应用程序（APP）SDK安全指南（草案）.</span></p>
      </div>
    </el-scrollbar> 
  </div>
</template>

<script>
import Nav from "@/components/documentsBase/Nav";
export default {
  components: { Nav },
  metaInfo: {
    title:'ITGSA | 移动智能终端生态联盟 | 应用安全标准',
    meta: [
      {
        name: 'keywords',
        content: 'ITGSA,生态联盟,安全,金标认证'
      },
      {
        name: 'description',
        content: '移动应用软件是移动智能终端安全架构中关键的要素之一，其种类和数量持续增长，全面影响着现代社会的方方面面，成为不可替代的公共基础软设施。但因为恶意应用的安装和运行，对移动智能终端的操作系统安全性、稳定性和用户数据安全等产生了负面影响。在移动智能终端上通过安装安全可信的应用是抵御恶意应用的威胁的重要保障，也是在移动智能终端硬件和操作系统之间建立起信任传链条的基础。'
      },
    ]
  },
  created() {},
  data() {
    return {
      navFixed: [
          {
            name: "引言",
            isActive:0,
            class:'#0',
          },
          {
            name: "前言",
            isActive:null,
            class:'#00',
          },
          {
            name: "一、 适用范围",
            isActive:null,
            class:'#1',
          },
          {
            name: "二、 规范性引用文件",
            isActive:null,
            class:'#2',
          },
          {
            name: "三、 术语、定义和缩略语",
            isActive:null,
            class:'#3',
            child:[
              {
                name: "3.1 术语和定义",
                isActive:null,
                class:'#3-1',
                child:[
                  {
                    name: "3.1.1 Intent",
                    isActive:null,
                    class:'#3-1-1',
                  },
                  {
                    name: "3.1.2 个人信息 personal information",
                    isActive:null,
                    class:'#3-1-2',
                  },
                  {
                    name: "3.1.3 个人敏感信息 personal sensitive information",
                    isActive:null,
                    class:'#3-1-3',
                  },
                  {
                    name: "3.1.4 敏感权限sensitive permission",
                    isActive:null,
                    class:'#3-1-4',
                  },
                  {
                    name: "3.1.5 移动智能终端smart mobile terminal",
                    isActive:null,
                    class:'#3-1-5',
                  },
                  {
                    name: "3.1.6 恶意应用malicious application",
                    isActive:null,
                    class:'#3-1-6',
                  },
                  {
                    name: "3.1.7 安全键盘secure keyboard",
                    isActive:null,
                    class:'#3-1-7',
                  },
                ],
              },
              {
                name: "3.2 缩略语",
                isActive:null,
                class:'#3-2',
              },
            ],
          },
          {
            name: "四、 移动智能终端安全架构",
            isActive:null,
            class:'#4',
          },
          {
            name: "五、 应用行为安全技术要求",
            isActive:null,
            class:'#5',
            child:[
              {
                name: "5.1 应用安装",
                isActive:null,
                class:'#5-1',
                child:[
                  {
                    name: "5.1.1 安装升级",
                    isActive:null,
                    class:'#5-1-1',
                  },
                  {
                    name: "5.1.2 恶意安装",
                    isActive:null,
                    class:'#5-1-2',
                  },
                ],
              },
              {
                name: "5.2 应用运行",
                isActive:null,
                class:'#5-2',
                child:[
                  {
                    name: "5.2.1 应用启动",
                    isActive:null,
                    class:'#5-2-1',
                  },
                  {
                    name: "5.2.2 应用权限",
                    isActive:null,
                    class:'#5-2-2',
                  },
                  {
                    name: "5.2.3 应用敏感操作",
                    isActive:null,
                    class:'#5-2-3',
                  },
                  {
                    name: "5.2.4 用流氓行为",
                    isActive:null,
                    class:'#5-2-4',
                  },
                ],
              },
            ],
          },
          {
            name: "六、 应用开发安全技术要求",
            isActive:null,
            class:'#6',
            child:[
              {
                name: "6.1 数据安全",
                isActive:null,
                class:'#6-1',
                child:[
                  {
                    name: "6.1.1 机密性",
                    isActive:null,
                    class:'#6-1-1',
                  },
                  {
                    name: "6.1.2 完整性",
                    isActive:null,
                    class:'#6-1-2',
                  },
                ],
              },
              {
                name: "6.2 编码安全",
                isActive:null,
                class:'#6-2',
                child:[
                  {
                    name: "6.2.1 编码规范",
                    isActive:null,
                    class:'#6-2-1',
                  },
                  {
                    name: "6.2.2 审计加固",
                    isActive:null,
                    class:'#6-2-2',
                  },
                  {
                    name: "6.2.3 第三方SDK规范",
                    isActive:null,
                    class:'#6-2-3',
                  },
                  {
                    name: "6.2.4 应用权限规范",
                    isActive:null,
                    class:'#6-2-4',
                  },
                  {
                    name: "6.2.5 组件安全规范",
                    isActive:null,
                    class:'#6-2-5',
                  },
                  {
                    name: "6.2.6 工具链安全规范",
                    isActive:null,
                    class:'#6-2-6',
                  },
                ],
              },
              {
                name: "6.3 业务安全",
                isActive:null,
                class:'#6-3',
                child:[
                  {
                    name: "6.3.1 业务鉴权规范",
                    isActive:null,
                    class:'#6-3-1',
                  },
                  {
                    name: "6.3.2 安全策略规范",
                    isActive:null,
                    class:'#6-3-2',
                  },
                ],
              },
              {
                name: "6.4 隐私保护规范",
                isActive:null,
                class:'#6-4',
              },
            ],
          },
          {
            name: "七、 应用内容安全要求",
            isActive:null,
            class:'#7',
            child:[
              {
                name: "7.1 基本要求",
                isActive:null,
                class:'#7-1',
              },
              {
                name: "7.2 政治内容管理要求",
                isActive:null,
                class:'#7-2',
              },
              {
                name: "7.3 暴恐血腥内容管理要求",
                isActive:null,
                class:'#7-3',
              },
              {
                name: "7.4 违禁品内容管理要求",
                isActive:null,
                class:'#7-4',
              },
              {
                name: "7.5 宗教、种族等内容管理要求",
                isActive:null,
                class:'#7-5',
              },
              {
                name: "7.6 色情内容管理要求",
                isActive:null,
                class:'#7-6',
              },
              {
                name: "7.7 赌博、彩票类内容管理要求",
                isActive:null,
                class:'#7-7',
              },
              {
                name: "7.8 恶意扣费、欺诈内容管理要求",
                isActive:null,
                class:'#7-8',
              },
              {
                name: "7.9 广告内容管理要求",
                isActive:null,
                class:'#7-9',
              },
            ],
          },
        ],
    };
  },
  mounted() {
    $('.nav-fixed-box .name').eq(0).css("color","#1989fa");
    this.scrollContainer = this.$refs['view-box'].wrap;  // 将 el-scrollbar 的 wrap 对象找出来，指定给 scroll-container 
    this.scrollContainer.addEventListener('scroll',utils.throttle(this.handleScroll,100,200));
  },
  methods: {
    scrollView(event){
      if($(event.target).attr("view")){
        this.$refs['view-box'].wrap.scrollTop = $($(event.target).attr("view")).position().top ;
      }
    },
    //滚动检查
    handleScroll() {
      //获取滚动时的高度
      let scrollTop = this.scrollContainer.scrollTop;
      if(scrollTop){
        for(let i=($(".nav-title").length-1);i>=0;i--){
          if (scrollTop > $(".nav-title").eq(i).position().top-30) { 
            $('.nav-fixed-box .name').css("color","#000");
            $('.nav-fixed-box .name').eq(i).css("color","#1989fa");
            this.awaitScroll($('.nav-fixed-box .name').eq(i).position().top);
            break;
          }
        }
      }else{
        $('.nav-fixed-box .name').css("color","#000");
        $('.nav-fixed-box .name').eq(0).css("color","#1989fa");
        this.awaitScroll(0);
      }
    },
    //同步目录滚动
    awaitScroll(top) {
      $('.nav-fixed-box').animate({
        scrollTop:top
      },300);
    },
  },
  destroyed() {
    this.scrollContainer.removeEventListener('scroll', this.handleScroll)
  },
};
</script>

<style scoped lang="scss">
table{
  td{
    padding: 10pt;
  }
}

/*主体*/
.page-box{
  position: relative;
  display: flex;
  .nav-fixed-box{
    width:300px;
    height: 100%;
    padding: 40px 15px;
    overflow-y: scroll;
    .nav-fixed{
      position: relative;
    }
  }
  .view-box{
    position: relative;
    padding: 60px;
  }
}
@media screen and (max-width: 800px) {
    .nav-fixed-box {
      display: none;
    }
}
</style>
